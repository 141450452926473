<template>
  <div class="equipment-fault-add">
    <van-nav-bar
      title="新增故障"
      :border="false"
      left-arrow
      @click-left="handleBack"
    />
    <div class="equipment-fault-box">
      <div class="equipment-name">
        {{ form.equipmentName }}
        <span class="equipment-no">{{ form.equipmentInfo }}</span>
      </div>
      <van-form ref="form">
        <van-field
          v-model="form.description"
          class="required"
          label="故障描述"
          placeholder="请填写故障描述"
          type="textarea"
          rows="2"
          autosize
          maxlength="500"
          show-word-limit
          :rules="[{ required: true, message: '请填写故障描述' }]"
        />
        <van-field
          v-model="form.typeName"
          class="required"
          is-link
          label="故障类型"
          readonly
          placeholder="请选择"
          input-align="right"
          :rules="[{ required: true, message: '请选择故障类型' }]"
          @click="visiable = true"
        />
        <van-field
          v-model="form.position"
          label="故障位置"
          placeholder="请填写故障位置"
          type="textarea"
          rows="2"
          autosize
          maxlength="500"
          show-word-limit
          label-align="top"
        />
      </van-form>
      <SelectPopupGrade
        ref="selectPopupGrade"
        v-model="form.typeName"
        :multi="false"
        :immediately="true"
        title="故障类型"
        :list="faultTypes"
        list-key="value"
        :visible="visiable"
        @change="popupGradeChange"
        @close="visiable = false"
      />
      <van-field
        v-model="form.faultHappenTime"
        label="故障时间"
        name="故障时间"
        is-link
        autocomplete="off"
        placeholder="请选择故障时间"
        @click="show = true"
      />
      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="pickerTime"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="pickerConfirm"
          @cancel="show = false"
        />
      </van-popup>
      <van-field
        key="故障图片"
        class="details-page__last-from-item"
        label="故障图片"
        name="uploader"
      >
        <template #input>
          <image-uploader
            key="fixInfo-image-uploader"
            ref="image-uploader"
            v-model="form.fileExtDTOS"
            :max-count="5"
            @change="val => (form.fileExtDTOS = val)"
          />
        </template>
      </van-field>
      <DevicePopup
        popup-height="70%"
        :visible="deviceVisible"
        @close="deviceVisible = false"
        @change="onDeviceChange"
      />
    </div>
    <div class="fault-submit">
      <van-button block type="primary" round @click="commitDeviceFault"
        >提 交</van-button
      >
    </div>
  </div>
</template>

<script>
import { commitDeviceFault, getFaultType } from "@/api/basics/equipment";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import DevicePopup from "@/components/DevicePopup.vue";
import { operateDialog } from "@/utils";
import selectTime from "@/views/accidentReport/components/selectTime.vue";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import { formatDates } from "@/views/accidentReport/utils/formDate";

export default {
  components: {
    SelectPopupGrade,
    DevicePopup,
    selectTime,
    ImageUploader
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visiable: false,
      deviceVisible: false, // 设备popup
      form: {
        type: "",
        typeName: "",
        equipmentId: "",
        equipmentName: "",
        description: ""
      },
      faultTypes: [],
      pickerTime: new Date(),
      show: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date()
    };
  },
  created() {
    // console.log("this.qqq", this.$route.params);
    const { info } = this.$route.params;
    let equipmentInfo = "";
    if (info.machineCode && !info.machineNo) {
      equipmentInfo = info.machineCode;
    }
    if (!info.machineCode && info.machineNo) {
      equipmentInfo = info.machineNo;
    }
    if (info.machineCode && info.machineNo) {
      equipmentInfo = `${info.machineCode}/${info.machineNo}`;
    }
    this.form.equipmentInfo = equipmentInfo;
    this.form.equipmentName = info.name;
    this.getSelects();
  },
  methods: {
    async handleBack() {
      try {
        await operateDialog({ message: "当前内容未保存，确定返回？" });
        this.$router.back();
      } catch (e) {
        console.log("e", e);
      }
    },
    pickerConfirm(val) {
      this.form.faultHappenTime = formatDates(val, "yyyy-MM-dd hh:mm:ss");
      this.pickerTime = val;
      this.show = false;
    },
    onDeviceChange(v) {
      this.form.equipmentId = v.id;
      this.form.equipmentName = v.name;
    },
    popupGradeChange(v) {
      const [item] = v;
      this.form.type = item.value;
      this.form.typeName = item.label;
    },
    getSelects() {
      // 获取故障类型
      getFaultType().then(res => {
        this.faultTypes = res;
      });
    },
    commitDeviceFault() {
      this.$refs.form.validate().then(async () => {
        try {
          await operateDialog({ message: "确定提交此故障?" });
          const params = {
            ...this.form,
            equipmentRecordId: [this.id]
          };
          commitDeviceFault(params)
            .then(res => {
              if (res) {
                this.$toast.success("提交成功");
                this.$router.back();
              }
            })
            .catch(e => {
              this.$toast.fail("提交失败, 请重试");
            });
        } catch (e) {
          console.log("e", e);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-fault-add {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-fault-box {
    flex: 1;
    height: 0;
    overflow-y: auto;
    box-sizing: border-box;
    .equipment-name {
      height: 30px;
      line-height: 30px;
      padding: 8px 16px;
      position: relative;
      font-size: 16px;
      color: #2e2e4d;
    }
    .equipment-name::after {
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      right: 16px;
      bottom: 0;
      left: 16px;
      border-bottom: 1px solid #ebedf0;
      transform: scaleY(0.5);
    }
    .equipment-no {
      font-size: 12px;
      color: #a0a2a6;
    }
    .required::before {
      content: "*";
      color: #f53f3f;
      position: absolute;
      left: 7px;
    }
  }
  .fault-submit {
    padding: 8px;
  }
}
</style>
