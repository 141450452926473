<template>
  <div class="select-time">
    <van-field
      v-model="time"
      :label="label"
      :name="name"
      is-link
      autocomplete="off"
      :placeholder="placeholder"
      :rules="rules"
      @click="show = true"
    />
    <van-popup v-model="show" position="bottom">
      <div class="popupTitle">{{ popupTitle }}</div>
      <van-datetime-picker
        v-model="pickerTime"
        type="datetime"
        :title="pickerTitle"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="pickerConfirm"
        @cancel="pickerCancel"
        @formatter="formatter"
      />
    </van-popup>
  </div>
</template>

<script>
import { formatDates } from "../utils/formDate";
export default {
  name: "SelectTime",
  props: {
    value: {
      type: [String, Date],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      // 表单校验时用到
      type: String,
      default: ""
    },
    rules: {},
    placeholder: {
      type: String,
      default: "请选择"
    },
    popupTitle: {
      type: String,
      default: "请选择时间"
    },
    pickerTitle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      time: "",
      pickerTime: "",
      show: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date()
    };
  },
  created() {},
  watch: {
    value(val) {
      this.time = formatDates(new Date(val), "yyyy-MM-dd hh:mm");
      this.pickerTime = new Date(val);
    }
  },
  methods: {
    formatter(type, val) {
      console.log(type, val);
    },
    pickerConfirm(val) {
      this.time = formatDates(val, "yyyy-MM-dd hh:mm");
      this.pickerTime = val;
      this.show = false;
    },
    pickerCancel() {
      this.show = false;
    }
  }
};
</script>

<style scoped lang="scss">
.popupTitle {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #333333;
  font-weight: bold;
}
</style>
